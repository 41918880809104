import { toast } from 'react-toastify';
import { QCSBaseObject } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBaseObject';
import { QCSBool } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBool';
import { QCSDouble } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSDouble';
import { QCSInt } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSInt';
import { QCSString } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSString';
import { QCSChoice } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSChoice';
import { QCSChoiceList } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSChoiceList';
import { QCSDate } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSDate';
import { IQCSFormContext } from '20.formLib/helpers/QCScriptLib/bridge/IQCSFormContext';
import { FormContextDico } from '20.formLib/helpers/QCScriptLib/bridge/types/qcsFormContext';
import { Choice } from '90.quickConnect.Models/models/declarations/choice';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { QCSInterpreter } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/QCInterpreter.implemen/QCSInterpreter';
import { IQCSHost } from '20.formLib/helpers/QCScriptLib/interfaces/IQCSHost';
import { AllFieldValueTypes, FieldDesc } from '90.quickConnect.Models/models';
import { toastifyOnChange } from '80.quickConnect.Core/helpers/toastifyOnChange';
import { isNumeric } from '80.quickConnect.Core/helpers/common';
import { parseNumber } from '80.quickConnect.Core/helpers/parseNumber';
import { QCSInstance } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSInstance';
import { FieldValue } from '20.formLib/DeclarationContainer/AllResolvers/QCScriptsResolver/types';
import { CaseInsensitiveMap } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/utils/CaseInsensitiveMap';
import { errorHandler } from '80.quickConnect.Core/helpers';
import { IMapperValue, MapperValue } from '20.formLib/helpers/QCScriptLib/bridge/mapper';
import { FieldType } from '90.quickConnect.Models/enums';

export class QCSFormContext implements IQCSFormContext {
  // Tag
  private static readonly TAG = '20.formLib/helpers/QCScriptLib/QuickConnect.API.Integration.Tests/QCSFormContext.ts';

  fieldValue: CaseInsensitiveMap<string, unknown>;

  fieldUpdated: CaseInsensitiveMap<string, unknown>;

  fieldReadonlyFlag: CaseInsensitiveMap<string, boolean>;

  fieldVisibilityFlag: CaseInsensitiveMap<string, boolean>;

  fieldMandatoryFlag: CaseInsensitiveMap<string, boolean>;

  warnings: string[];

  informations: string[];

  formContextChildren: QCSFormContext[] = [];

  isFormContextChildren = false;

  static debugs: string[];

  baseFieldScope: string;

  private qcInterpreter: QCSInterpreter | null = null;

  private formManager: IQCSHost | null = null;

  private mapperValue: IMapperValue;

  constructor(formManager: IQCSHost) {
    this.formManager = formManager;
    this.fieldValue = new CaseInsensitiveMap();
    this.fieldUpdated = new CaseInsensitiveMap();
    this.fieldReadonlyFlag = new CaseInsensitiveMap();
    this.fieldVisibilityFlag = new CaseInsensitiveMap();
    this.fieldMandatoryFlag = new CaseInsensitiveMap();
    this.warnings = [];
    this.informations = [];
    this.baseFieldScope = '';
    QCSFormContext.debugs = [];
    this.mapperValue = new MapperValue();
    toastifyOnChange(this.formManager);
  }

  getFormContextOfChild(index: number): IQCSFormContext | null {
    if (this.formContextChildren.length === 0 || this.formContextChildren.length < index) return null;

    return this.formContextChildren.at(index)!;
  }

  initializeWithFieldDesc(field: FieldDesc): this {
    const { items, fullPathId } = field;

    if (items.length === 0) return this;

    const values = items.reduce((acc: FieldValue[], { fullPathId: fullId, value }: FieldDesc) => {
      const optimizedValue = value as unknown;
      return [...acc, [fullId, optimizedValue] satisfies FieldValue];
    }, []);

    const formContextDico: FormContextDico = {
      fieldValue: this._createFieldValues(values),
      fieldReadonlyFlag: new CaseInsensitiveMap<string, boolean>(),
      fieldVisibilityFlag: new CaseInsensitiveMap<string, boolean>(),
      warnings: [],
      informations: [],
    };

    this.initFieldDico(formContextDico);

    this.baseFieldScope = fullPathId;

    this.isFormContextChildren = true;

    this.formContextChildren = this._createFormContextChildren(field);

    return this;
  }

  initFieldDico({
    fieldValue = new CaseInsensitiveMap<string, unknown>(),
    fieldReadonlyFlag = new CaseInsensitiveMap<string, boolean>(),
    fieldVisibilityFlag = new CaseInsensitiveMap<string, boolean>(),
  }: FormContextDico): boolean {
    if (fieldValue?.size > 0) {
      fieldValue.forEach((value: unknown, key: string) => {
        if (this.fieldValue.has(key)) return false;
        this.fieldValue.set(key, value);
        return true;
      });
    }

    if (fieldReadonlyFlag?.size > 0) {
      fieldReadonlyFlag.forEach((flag: boolean, key: string) => {
        if (this.fieldReadonlyFlag.has(key)) return false;
        this.fieldReadonlyFlag.set(key, flag);
        return true;
      });
    }

    if (fieldVisibilityFlag?.size > 0) {
      fieldVisibilityFlag.forEach((flag: boolean, key: string) => {
        if (this.fieldVisibilityFlag.has(key)) return false;
        this.fieldVisibilityFlag.set(key, flag);
        return true;
      });
    }

    return true;
  }

  getValAsBoolean(qcParams: QCSBaseObject[]): boolean | null {
    const bf = this.getValAsXXX(qcParams, 'getValAsBoolean');

    if (!bf) return null;

    const { value } = bf;

    const boolValue: boolean | undefined = this.mapperValue.mapToBoolean(value);

    if (boolValue === undefined) return null;

    return boolValue;
  }

  getValAsDate(qcParams: Array<QCSBaseObject>): Date | null {
    const bf = this.getValAsXXX(qcParams, 'getValAsDate');

    if (!bf) return null;

    const dtValue = this.mapperValue.mapToDateTime(bf.value);

    if (!dtValue) return null;

    return dtValue;
  }

  getValAsDouble(qcParams: Array<QCSBaseObject>): number | null {
    const bf = this.getValAsXXX(qcParams, 'getValAsDouble');

    if (!bf) return null;

    const { value } = bf;

    const dbleValue: number | undefined = this.mapperValue.mapToDouble(value);

    if (dbleValue === undefined) return null;

    return dbleValue;
  }

  getValAsInt(qcParams: Array<QCSBaseObject>): number | null {
    const bf = this.getValAsXXX(qcParams, 'getValAsInt');

    if (!bf) return null;

    const { value } = bf;

    const intValue: number | undefined = this.mapperValue.mapToInteger(value);

    if (intValue === undefined) return null;

    return intValue;
  }

  getValAsText(qcParams: Array<QCSBaseObject>): string | null {
    const bf = this.getValAsXXX(qcParams, 'getValAsText');
    if (!bf) return null;

    let str: string | undefined = '';
    switch (bf.fieldType) {
      case FieldType.Combo:
      case FieldType.RadioList:
        const choiceVal: Choice | null = this.getValAsChoice(qcParams);
        str = choiceVal?.label;
        break;
      case FieldType.Slider:
        const dbleValue: number | null = this.getValAsDouble(qcParams);
        str = this.mapperValue.mapToString(dbleValue, bf.fieldType);
        break;
      default:
        str = this.mapperValue.mapToString(bf.value, bf.fieldType);
    }

    if (str === undefined) return null;

    return str;
  }

  getValAsChoice(qcParams: Array<QCSBaseObject>): Choice | null {
    const bf = this.getValAsXXX(qcParams, 'getValAsChoice');

    if (!bf) return null;

    const choice: Choice | undefined = this.mapperValue.mapToChoice(bf.value);

    if (!choice) return null;

    return choice;
  }

  getValAsChoiceList(qcParams: Array<QCSBaseObject>): Choice[] | null {
    const bf = this.getValAsXXX(qcParams, 'getValAsChoiceList');

    if (!bf) return null;

    const choiceList: Choice[] | undefined = this.mapperValue.mapToChoiceList(bf.value);

    if (!choiceList) return null;

    return choiceList;
  }

  /**
   * lire le flag lecture seule d'un champ ou du formulaire
   *
   * @param qcParams
   */
  public getReadOnly(qcParams: Array<QCSBaseObject>): QCSBaseObject {
    if (qcParams.length > 0 && qcParams.at(0) instanceof QCSString) {
      const qcs = qcParams.at(0) as QCSString;
      if (qcs !== null) {
        // Récupère le controle
        const sid = this.getFieldPath(qcs.value);

        // Regardons si la valeur de ce champ est à l'intérieur du dico fieldReadOnlyFlag
        if (this.fieldReadonlyFlag.has(sid)) return new QCSBool(this.fieldReadonlyFlag.get(sid) ?? true);

        // Sinon on fait appel à la méthode externe afin d'aller rechercher dans les flattenFields du formulaire
        const isReadOnly = this.formManager?.qcscriptStore?.getReadOnlyField(sid) ?? true;

        return new QCSBool(isReadOnly);
      }
    }

    // Retourne le flag du formulaire
    const formIsReadOnly: boolean = this.formManager?.qcscriptStore?.isReadOnly() ?? true;

    return new QCSBool(formIsReadOnly);
  }

  setValue(qcParams: QCSBaseObject[], sid?: string): boolean {
    try {
      if (qcParams?.length !== 2) throw new Error('setValue needs string and object as argument');

      const id = qcParams[0] as QCSString;
      if (!sid) {
        sid = this.getFieldPath(id.value);
      }
      // eslint-disable-next-line prefer-destructuring
      const val: QCSBaseObject = qcParams[1];

      const bf: FieldDesc | undefined = this.formManager?.getValue(sid);

      if (!bf) throw new Error('BaseField not found for this fullId: ' + sid);

      const nextValue: unknown = this.mapperValue.convert(
        bf,
        val.getValue(),
        val,
        this.formManager?.getFlattenFields() ?? [],
      );

      // Verification des données proposées
      if (!this.formManager?.validate(sid, nextValue)) return false;

      this.fieldValue.set(sid, nextValue);
      this.fieldUpdated.set(sid, nextValue);
      this.formManager?.updateFlattenFieldValue(sid, nextValue as AllFieldValueTypes);

      if (this.isFormContextChildren) {
        this.formManager?.setValueMainFormContext(qcParams, sid);
      }
      this.formManager?.qcscriptStore?.callingNewInterpreter(sid);

      return true;
    } catch (error: unknown) {
      errorHandler(QCSFormContext.TAG, error, 'setValue', 'trace');
      return false;
    }
  }

  setVisible = (qcParams: Array<QCSBaseObject>): void => {
    if (qcParams?.length !== 2) throw new Error('setValue needs string and object as argument');

    if (qcParams.at(0) instanceof QCSString) {
      const qcs: QCSString = qcParams.at(0) as QCSString;
      const sid = this.getFieldPath(qcs.value);

      if (qcParams.at(1) instanceof QCSBool) {
        const qcb: QCSBool = qcParams.at(1) as QCSBool;
        this.fieldVisibilityFlag.set(sid, qcb.value);
      }
    }
  };

  /**
   * Mettre en lecture seule ou en lecture ecriture un champ
   *
   * @param qcParams
   */
  public setReadOnly = (qcParams: Array<QCSBaseObject>): void => {
    const [qcs, qcb] = qcParams;
    if (qcs instanceof QCSString) {
      const sid = this.getFieldPath(qcs.value);
      if (qcb instanceof QCSBool) {
        this.fieldReadonlyFlag.set(sid, qcb.value);
      }
    }
  };

  /**
   * Mettre en lecture seule ou en lecture ecriture un champ
   *
   * @param qcParams
   */
  public setMandatory = (qcParams: Array<QCSBaseObject>): void => {
    const [qcs, qcb] = qcParams;
    if (qcs instanceof QCSString) {
      const sid = this.getFieldPath(qcs.value);
      if (qcb instanceof QCSBool) {
        this.fieldMandatoryFlag.set(sid, qcb.value);
      }
    }
  };

  showInformation(qcParams: Array<QCSBaseObject>): void {
    if (qcParams?.length !== 1) throw new Error('showInformation need String as argument');
    if (this.formManager?.qcscriptStore === undefined) throw new Error('qcscriptStore is undefined');
    const { toastId, setToastId } = this.formManager?.qcscriptStore ?? {};

    const info = qcParams[0] as QCSString;
    if (info.value === '') {
      toast.dismiss();
      setToastId(undefined);
    } else if (toastId) {
      toast.update(toastId, {
        render: info.value,
        type: toast.TYPE.INFO,
      });
    } else {
      setToastId(toast.info(info.value));
    }
  }

  showWarning(qcParams: Array<QCSBaseObject>): void {
    if (qcParams?.length !== 1) throw new Error('showWarning need String as argument');
    if (this.formManager?.qcscriptStore === undefined) throw new Error('qcscriptStore is undefined');
    const { toastId, setToastId } = this.formManager?.qcscriptStore ?? {};

    const warn = qcParams[0] as QCSString;

    if (warn.value === '') {
      toast.dismiss();
      setToastId(undefined);
    } else if (toastId) {
      toast.update(toastId, {
        render: warn.value,
        type: toast.TYPE.ERROR,
      });
    } else {
      setToastId(toast.error(warn.value));
    }
  }

  private getValAsXXX(qcParams: QCSBaseObject[], fctName: string): FieldDesc | undefined {
    try {
      if (qcParams.length === 1 && qcParams[0] instanceof QCSString) {
        const sid = this.getFieldPath(qcParams[0].value);

        if (!this.fieldValue.has(sid)) {
          // Il se peut que cela soit un enfant
          throw new Error(`${fctName} can't find ${sid}`);
        }

        return this.formManager?.getValue(sid);
      } else {
        throw new Error(`${fctName} need string as argument`);
      }
    } catch (error: unknown) {
      errorHandler(QCSFormContext.TAG, error, 'getValAsXXX');
    }
  }

  /**
   * Permet de retourner le nom du formulaire ou bien le nom du modèle si 1 est passé en paramètre
   *
   * @param {Array<QCSBaseObject>} qcParams
   * @memberof QCSFormContext
   */
  getFormName = (qcParams: Array<QCSBaseObject>): QCSBaseObject => {
    if (qcParams.length > 0 && qcParams[0] instanceof QCSInt) {
      const [qci] = qcParams;
      if (qci.value == 1 && this.qcInterpreter instanceof QCSInterpreter) {
        // Retourne le nom du script courant
        return new QCSString(this.qcInterpreter.getCurrentScriptName());
      }
    }

    if (this.formManager === null) return new QCSString('');
    // Retourne le nom du formulaire courant
    return new QCSString(this.formManager.getForm()?.name ?? '');
  };

  /**
   * Retourne la somme en entiers des champs respectant le pattern indiqué en paramètre.
   *
   * @param {Array<QCSBaseObject>} qcParams
   * @memberof QCSFormContext
   */
  sumValAsInt = (qcParams: Array<QCSBaseObject>): QCSBaseObject => {
    const [qcs] = qcParams;

    if (qcs instanceof QCSString === false) return QCSBaseObject.QCSNull;

    const sumVal: number | null = this._getSumValue(true, (qcs as QCSString).value);

    if (sumVal === null) return QCSBaseObject.QCSNull;

    return new QCSInt(sumVal);
  };

  /**
   * Retourne la somme en décimale des champs respectant le pattern indiqué en paramètre.
   *
   * @param {Array<QCSBaseObject>} qcParams
   * @memberof QCSFormContext
   */
  sumValAsDouble = (qcParams: Array<QCSBaseObject>): QCSBaseObject => {
    const [qcs] = qcParams;

    if (qcs instanceof QCSString === false) return QCSBaseObject.QCSNull;

    const sumVal: number | null = this._getSumValue(false, (qcs as QCSString).value);

    if (sumVal === null) return QCSBaseObject.QCSNull;

    return new QCSDouble(sumVal);
  };

  callQCSModule(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    // eslint-disable-next-line
    const fc: IQCSFormContext = this;
    switch (methodId) {
      case 1: // setValue
        return new QCSBool(fc.setValue(qcParams));

      case 2: // getValAsBoolean
        const boolValue: boolean | null = fc.getValAsBoolean(qcParams);
        return boolValue !== null ? new QCSBool(boolValue) : QCSBaseObject.QCSNull;

      case 3: {
        // getValAsDouble
        const dbleValue: number | null = fc.getValAsDouble(qcParams);
        if (dbleValue === null) return QCSBaseObject.QCSNull;
        return new QCSDouble(dbleValue);
      }
      case 4: // getValAsInt
        const intValue: number | null = fc.getValAsInt(qcParams);
        if (intValue === null) return QCSBaseObject.QCSNull;

        return new QCSInt(intValue);

      case 5: // getValAsDate
        const dateValue = fc.getValAsDate(qcParams);
        if (dateValue === null || (dateValue instanceof Date && Number.isNaN(dateValue.getTime())))
          return QCSBaseObject.QCSNull;

        return new QCSDate(dateValue);

      case 6: // getValAsChoice
        const c = fc.getValAsChoice(qcParams);
        return c ? new QCSChoice(c) : QCSBaseObject.QCSNull;

      case 7: // getValAsChoiceList
        const cl = fc.getValAsChoiceList(qcParams);

        return cl ? new QCSChoiceList(cl) : QCSBaseObject.QCSNull;

      case 8: // getValAsText
        const s = fc.getValAsText(qcParams);
        return typeof s === 'string' ? new QCSString(s) : QCSBaseObject.QCSNull;

      case 9: // setReadOnly
        this.setReadOnly(qcParams);
        return null;

      case 10: // setVisible
        this.setVisible(qcParams);
        return null;

      case 11: // showWarning
        this.showWarning(qcParams);
        return QCSBaseObject.QCSNull;

      case 12: // showInformation
        this.showInformation(qcParams);
        return QCSBaseObject.QCSNull;

      case 14: // getReadOnly
        return this.getReadOnly(qcParams);

      case 15: // getFormName
        return this.getFormName(qcParams);

      case 16: // setMandatory
        this.setMandatory(qcParams);
        return null;

      case 17: // sumValueAsDouble
        return this.sumValAsInt(qcParams);

      case 18: // sumValueAsDouble
        return this.sumValAsDouble(qcParams);

      case 21: // getFormContext
        return this.getFormContext(qcParams);

      case 22: // count
        return this.count();

      default:
        return null;
    }
  }

  public setScriptInfo = (interpreter: QCSInterpreter): void => {
    this.qcInterpreter = interpreter;
  };

  public static staticCall(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    switch (methodId) {
      case 1:
      case 2:
      case 13: // debug
        this.debug(qcParams);
        return null;

      default:
        return null;
    }
  }

  getBaseFieldScope = (): string => this.baseFieldScope;

  setBaseFieldScope = (baseFieldScope: string): void => {
    if (baseFieldScope.includes('.'))
      this.baseFieldScope = baseFieldScope.substring(0, baseFieldScope.lastIndexOf('.'));
  };

  resetFieldUpdated = (): void => {
    this.fieldUpdated = new CaseInsensitiveMap<string, unknown>();
  };

  /**
   * Appelée par QCScript pour mettre une trace
   *
   * @param qcParams
   */
  public static debug(qcParams: Array<QCSBaseObject>): void {
    if (qcParams.at(0) instanceof QCSString) {
      const qcs = qcParams.at(0) as QCSString;
      CustomLogger.getInstance().log(QCSFormContext.TAG, `QCSCRIPT: ${qcs.value}`);
    }
  }

  private getFieldPath = (relativePath: string): string => {
    relativePath = relativePath.toLowerCase();
    let strTmp: string =
      this.getBaseFieldScope() !== '' ? this.getBaseFieldScope().toLowerCase() + '.' + relativePath : relativePath;

    if (this.fieldValue.ends(strTmp)) return strTmp;

    // Il se peut que cela soit un enfant
    const strTmpChild = this._getSubPath(strTmp);

    if (this.fieldValue.has(strTmpChild)) return strTmpChild;

    if (!strTmp.includes('.')) return strTmp;

    // Il se peut que cela soit un relativePath à un modèle, groupe, etc...
    strTmp = this._getSubPath(strTmp);

    if (this.fieldValue.has(strTmp)) return strTmp;

    const pathArray: string[] = strTmp.split('.');
    const uniquePathArray: string[] = [...new Set(pathArray)];
    return uniquePathArray.join('.');
  };

  private _getSubPath = (path: string): string => {
    if (this.fieldValue.has(path)) return path;

    const absolutePaths: string[] = [];

    this.fieldValue.forEach((value: unknown, key: string) => {
      if (key.includes(path)) {
        if (!key.includes('.') && path === key) absolutePaths.push(key);
        else {
          const ids = key.split('.');
          if (ids.find((id: string) => id === path)) absolutePaths.push(key);
        }
      }
    });

    if (absolutePaths.length > 0) return absolutePaths.at(0)!;

    // Regardons les parents
    if (path.includes('.')) {
      const pathSplitted: string[] = path.split('.');
      const lastId = pathSplitted.pop();
      if (lastId) {
        pathSplitted.pop();
        pathSplitted.push(lastId);
        const newPath = pathSplitted.join('.');
        return this._getSubPath(newPath);
      }
    }

    CustomLogger.getInstance().info(QCSFormContext.TAG, '_getSubPath failed: absolutePath not found');
    return '';
  };

  private _getSumValue = (integerFormat: boolean, pathToSearch: string): number | null => {
    // Prendre en compte si on utilise le FormContext général (tous les champs) ou bien le current FormContext
    const fieldsScope: CaseInsensitiveMap<string, unknown> = this._getRootOrThisScope(pathToSearch);

    const sum: number | null = Array.from(fieldsScope.values()).reduce((acc: number | null, current: unknown) => {
      if (isNumeric(current as AllFieldValueTypes)) {
        const num = parseNumber(current);
        if (num === undefined) return acc;
        const digitVal = integerFormat ? (Number.isInteger(num) ? num : Math.round(num)) : num;

        return acc === null ? digitVal : acc + digitVal;
      } else {
        return acc;
      }
    }, null);

    // Calculer la somme
    return sum;
  };

  private _getRootOrThisScope = (pathToSearch: string): CaseInsensitiveMap<string, unknown> => {
    const fieldsValueFiltered = new CaseInsensitiveMap<string, unknown>();
    const relativePath: string = pathToSearch.replace('/', '').replace('*', '');
    if (pathToSearch?.startsWith('/')) {
      this.fieldValue.forEach((value: unknown, key: string) => {
        if (key.includes(relativePath)) {
          fieldsValueFiltered.set(key, value);
        }
      });
    } else {
      this.fieldValue.forEach((value: unknown, key: string) => {
        if (key.startsWith(this.baseFieldScope) && key.includes(relativePath)) {
          fieldsValueFiltered.set(key, value);
        }
      });
    }

    return fieldsValueFiltered;
  };

  public setFormContext = (formManager: IQCSHost): void => {
    this.formManager = formManager;
    this.fieldValue = new CaseInsensitiveMap();
    this.fieldUpdated = new CaseInsensitiveMap();
    this.fieldReadonlyFlag = new CaseInsensitiveMap();
    this.fieldVisibilityFlag = new CaseInsensitiveMap();
    this.fieldMandatoryFlag = new CaseInsensitiveMap();
    this.warnings = [];
    this.informations = [];
    this.baseFieldScope = '';
    QCSFormContext.debugs = [];
  };

  getFormContext(qcParams: QCSBaseObject[]): QCSBaseObject {
    // Est ce que l'on demande un FormContext enfant
    const index = qcParams.length > 1 ? (qcParams.at(1) instanceof QCSInt ? (qcParams.at(1) as QCSInt).value : -1) : -1;

    // Est ce que l'on indique un champ pour récupérer le formContext
    // Sinon on prend le courant
    let bfId = null;
    if (qcParams.at(0) instanceof QCSString) {
      const qcs = qcParams.at(0) as QCSString;
      bfId = qcs.value;
    }

    const fc = this.getOwnedFormContext(bfId, index);

    return fc !== null ? new QCSInstance(fc) : QCSBaseObject.QCSNull;
  }
  count(): QCSBaseObject {
    return new QCSInt(this.formContextChildren.length);
  }

  public getOwnedFormContext(id: string | null = null, index = -1): IQCSFormContext | null {
    let fc: IQCSFormContext | null = null;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    if (id === null || id === '') fc = this;
    else fc = this.formManager?.qcscriptStore?.getOwnedFormContext(this.getFieldPath(id)) ?? null;

    if (fc !== null && index > -1) return fc.getFormContextOfChild(index);

    return fc;
  }

  private _createFieldValues(values: FieldValue[]): CaseInsensitiveMap<string, unknown> {
    const fieldValues = new CaseInsensitiveMap<string, unknown>();
    if (values.length > 0)
      values.forEach(([fullPathId, value]: FieldValue) => {
        fieldValues.set(fullPathId, value);
      }, this);

    return fieldValues;
  }

  private _createFormContextChildren(field: FieldDesc): QCSFormContext[] {
    const { items } = field;

    if (items.length === 0 || !this.formManager) return [];

    return items.map((child: FieldDesc) => {
      const fc = new QCSFormContext(this.formManager!);
      return fc.initializeWithFieldDesc(child);
    }, this);
  }
}
