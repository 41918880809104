import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { TopBarName } from '../LayOut/TopBar/const';
import { MainBox, TopBarStyle } from '../LayOut/TopBar/style';
import { useIsMobile } from '../../../../80.quickConnect.Core/hooks';
import LeftItems from '../LayOut/TopBar/LeftItems';
import { ErrorComponentProps } from './types';
import { Name } from './const';
import { HOME } from '10.quickConnect.app/routes/paths';
import { ListColumnForUserSettingListContainer } from '10.quickConnect.app/components/domain/Home/styles';

const ErrorComponent = ({ error }: ErrorComponentProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useIsMobile();

  const goHome = useCallback(() => {
    navigate(HOME);
    window.location.reload(); // Force app to reload after crash
  }, [navigate]);

  return (
    <>
      <AppBar
        elevation={0}
        data-cy={TopBarName}
        color={isMobile ? 'default' : 'transparent'}
        position="sticky"
        id="topBarComponent"
        sx={TopBarStyle(theme)}
      >
        <Box sx={MainBox} onClick={goHome}>
          <LeftItems showLeftMenuButton={false}>
            <Typography variant={'h3'}>{t('qcapp_common_errors_label')}</Typography>
          </LeftItems>
        </Box>
      </AppBar>
      <Stack
        data-cy={Name}
        gap={'0.5em'}
        sx={{
          ...ListColumnForUserSettingListContainer(theme),
          width: { sm: '100%', md: '60%' },
          height: 'auto',
          padding: '20px',
        }}
      >
        <Typography>{error}</Typography>
        <Button variant={'contained'} onClick={goHome}>
          {t('qcapp_common_home_page')}
        </Button>
      </Stack>
    </>
  );
};

export default ErrorComponent;
