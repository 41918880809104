import React from 'react';
import { SxProps } from '@mui/system';
import styled from '@emotion/styled';
import { InputLabel, TextField, Breakpoints } from '@mui/material';

import { blackColor, kraft400, kraft500, kraft600, pureWhiteColor, slateGrey800 } from '00.app/theme';

export const MainBoxStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: pureWhiteColor,
  backgroundPosition: '50%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage: 'url(assets/login_background.jpg)',
  border: {
    xs: '16px solid white',
    sm: '32px solid white',
    md: '48px solid white',
    lg: '64px solid white',
  },
  height: '100%',
  minHeight: '100vh',
  width: '100%',
  overflow: 'auto',
};

export const CardStyle: SxProps = {
  width: { xs: '80vw', md: '80vw' },
  maxWidth: { xs: 400, sm: 400, md: 500, lg: 500 },
  backgroundColor: kraft500,
  color: pureWhiteColor,
};

export const ArrowStyle: SxProps = {
  position: 'absolute',
  top: 0,
  right: 0,
};

export const BallStyle: SxProps = {
  position: 'absolute',
  bottom: {
    xs: '40px',
    sm: '70px',
    md: '90px',
    lg: '105px',
  },
  right: {
    xs: '35px',
    sm: '65px',
    md: '85px',
    lg: '110px',
  },
};

export const QcLogoStyle: React.CSSProperties = {
  width: '30%',
};

export const CardMediaStyle: SxProps = {
  width: { xs: '30vw', sm: '30vw', md: '12vw', lg: '12vw' },
  margin: { xs: '1vh auto', sm: '1vh auto', md: '2vh auto', lg: '2vh auto' },
};

export const TypoStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'justify',
  padding: '0.5em',
  color: pureWhiteColor,
  fontSize: { md: '1em' },
};

export const TypoAppVersionStyle = (breakpoints: Breakpoints): SxProps => ({
  ...TypoStyle,
  fontSize: { sm: '0.8em', md: '1em' },
  fontWeight: 'bold',
  [breakpoints.up('sm')]: {
    color: blackColor,
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  [breakpoints.down('sm')]: {
    fontSize: '0.8em',
    color: pureWhiteColor,
  },
});

export const CustomInputLabelStyle = styled(InputLabel)`
  margin: 1em auto 0.8em;
  text-transform: uppercase;
  font-weight: 500;
  color: ${pureWhiteColor};
`;

export const CardActionsStyle = (flag: boolean): SxProps => ({
  display: 'flex',
  justifyContent: `${flag ? 'space-between' : 'flex-end'}`,
});

export const CustomTextFieldStyle = styled(TextField)({
  '& .MuiInputBase-root': {
    backgroundColor: pureWhiteColor,
    color: slateGrey800,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '2px',
      borderColor: kraft400,
    },
    '&:hover fieldset': {
      borderWidth: '2px',
      borderColor: kraft600,
    },
    '&.Mui-focused fieldset': {
      borderWidth: '2px',
      borderColor: kraft600,
    },
    '& .MuiInputAdornment-root': {
      '& .MuiButtonBase-root': {
        '& .MuiSvgIcon-root': {
          color: slateGrey800,
        },
      },
    },
  },
});

export const CguBoxStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const CguLinkStyle: SxProps = {
  display: 'flex',
  alignSelf: 'center',
};
