import { format } from 'date-fns';
import { numericLocaleFormatLitteral } from '80.quickConnect.Core/helpers/numericLocaleFormatLitteral';
import { FieldType } from '90.quickConnect.Models/enums';
import {
  FieldDesc,
  AllFieldValueTypes,
  MetadataDesc,
  QCNotification,
  Choice,
  ComboDesc,
  HierarchicalChoice,
  HierarchicalDesc,
} from '90.quickConnect.Models/models';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { isQCNotification, parseNotificationData } from '80.quickConnect.Core/helpers/common';
import { JsonWrapper, flatten } from '80.quickConnect.Core/helpers';
import { isAnAlertChoice, isAnArrayOfChoice, isChoice } from '90.quickConnect.Models/guards';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';

/**
 * Permet de mettre à jour les champs en fonction de leur fullPathId et selon le type de champ
 *
 * @param {FieldDesc} field
 * @param {string} fullPathIdToUpdate
 * @param {AllFieldValueTypes} nextValue
 * @return {boolean}
 */
const updateValueByFieldType = (
  field: FieldDesc,
  fullPathIdToUpdate: string,
  nextValue: AllFieldValueTypes,
): boolean => {
  const { fullPathId, fieldType, value: prevValue } = field;
  // Ne prendre en compte si le champ à modifier correspond au fullPathId et que la valeur de celui a bien changé
  if (!fullPathIdToUpdate.endsWith(fullPathId.toLowerCase()) || prevValue === nextValue) return false;

  switch (fieldType) {
    case FieldType.Label:
      field.label = typeof nextValue === 'string' ? nextValue : '';
      field.value = null;
      break;

    case FieldType.Alert:
      if (isAnAlertChoice(nextValue)) field.value = nextValue;
      else if (
        typeof nextValue === 'string' &&
        (nextValue === 'Incident' || nextValue === 'Alerte' || nextValue === 'RAS')
      ) {
        const c: Choice = {
          label: nextValue,
          value: nextValue,
          data: undefined,
        };

        field.value = c;
      }
      break;

    case FieldType.Attachment:
      field.value = nextValue;
      field?.items?.forEach((item) => {
        (nextValue as MetadataDesc[])?.forEach((v) => {
          if (v.id === item.id) {
            item.value = v.value;
          }
        });
      });
      break;

    case FieldType.Text:
      if (nextValue instanceof Date) {
        field.value = format(nextValue, DateTimeExtension.FORMAT_ISO_8601_SEPARATOR_TZ);
        break;
      } else if (isAnAlertChoice(nextValue)) {
        field.value = nextValue.value;
      } else field.value = nextValue;
      break;

    case FieldType.Notification: {
      if (nextValue === undefined) break;
      if (isQCNotification(nextValue)) field.value = nextValue;
      if (typeof nextValue === 'string') {
        const { autoSend } = field.value as QCNotification;
        const notificationDataValue = parseNotificationData(nextValue, autoSend);

        field.value = notificationDataValue;
      }
      break;
    }

    case FieldType.Combo:
    case FieldType.RadioList:
      if (isChoice(nextValue)) {
        field.value = nextValue;
        break;
      }

      if (typeof nextValue === 'string') {
        if (nextValue.startsWith('{')) {
          const c = JsonWrapper.parse<Choice>(isChoice)(nextValue);

          field.value = c ?? null;
        } else {
          const c = (field as ComboDesc).listChoice?.find(
            ({ label, value: choiceVal }: Choice) =>
              nextValue.localeCompare(label, undefined, { sensitivity: 'base' }) === 0 ||
              nextValue.localeCompare(choiceVal, undefined, { sensitivity: 'base' }) === 0,
          );

          field.value = c ?? null;
        }
      }
      break;

    case FieldType.CheckBoxList:
    case FieldType.HierarchicalList:
      if (isAnArrayOfChoice(nextValue)) {
        field.value = nextValue;
        break;
      }

      if (typeof nextValue === 'string') {
        if (nextValue.startsWith('[')) {
          const cl = JsonWrapper.parse<Choice[]>(isAnArrayOfChoice)(nextValue);

          field.value = cl ?? [];
        } else {
          const nextValues = nextValue.split(new RegExp('[,;]')).map((v) => v.trim());
          const cl: Choice[] = [];

          nextValues.forEach((val: string) => {
            if (fieldType === FieldType.HierarchicalList) {
              const { listChoice } = field as unknown as HierarchicalDesc;
              cl.push(
                ...flatten(listChoice ?? [], (hc) => hc.children).filter(
                  ({ label, value }: HierarchicalChoice) =>
                    StringExtension.isTheSame(val, label) || StringExtension.isTheSame(val, value),
                ),
              );
            } else {
              const { listChoice } = field as unknown as ComboDesc;

              const newValues =
                listChoice?.filter(
                  ({ label, value }: Choice) =>
                    StringExtension.isTheSame(val, label) || StringExtension.isTheSame(val, value),
                ) ?? [];
              cl.push(...newValues);
            }
          });

          field.value = cl ?? [];
        }
      }
      break;

    case FieldType.ReadOnlyValue: {
      switch (true) {
        case typeof nextValue === 'number':
          const optionsNumberFormat: Intl.NumberFormatOptions = {
            minimumFractionDigits: 0,
            maximumFractionDigits: 8,
            useGrouping: false,
          };

          field.value = numericLocaleFormatLitteral(nextValue as number, optionsNumberFormat);
          break;

        case nextValue instanceof Date:
          field.value = format(nextValue as Date | DateTimeExtension, DateTimeExtension.FORMAT_ISO_8601_SEPARATOR_TZ);
          break;

        default:
          field.value = nextValue;
          break;
      }

      break;
    }

    case FieldType.DateTime:
      switch (true) {
        case nextValue instanceof Date:
          field.value = nextValue;
          break;

        case typeof nextValue === 'string':
          const nextValueParsed = DateTimeExtension.parseMultiFormat(nextValue as string);

          field.value = nextValueParsed;
          break;

        default:
          field.value = null;
          break;
      }
      break;

    default:
      field.value = nextValue;
      break;
  }

  return true;
};

const updateValueFieldOrChild = (
  fullPathId: string,
  field: FieldDesc,
  value: AllFieldValueTypes,
): [FieldDesc, boolean] => {
  let changeMade = updateValueByFieldType(field, fullPathId, value);

  if (!changeMade && field.items !== undefined && field.items.length > 0) {
    field.items.forEach((child) => {
      const [updatedChild, childChanged] = updateValueFieldOrChild(fullPathId, child, value);
      if (childChanged) changeMade = childChanged;
      child = updatedChild;
    });
  }

  return [field, changeMade];
};

export default updateValueFieldOrChild;
